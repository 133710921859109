import React, { useState } from "react";
import Fieldglass from "./WebScraping/Fieldglass";
import Test from "./WebScraping/Test";

export default function WebScraping() {
  const [currentPage, setCurrentPage] = useState("Menu");

  const goTo = page => {
    setCurrentPage(page);
  };

  const renderPage = () => {
    switch (currentPage) {
      case "Fieldglass":
        return <Fieldglass />;
      case "Test":
        return <Test />;
      default:
        return (
          <React.Fragment>
            <p onClick={() => goTo("Fieldglass")}>Fieldglass</p>
            <p onClick={() => goTo("Test")}>Test</p>
          </React.Fragment>
        );
    }
  };

  return (
    <div className="WebScraping">
      <h2>Web Scraping</h2>
      {renderPage()}
    </div>
  );
}
