import React from "react";

export default function FutureProjects() {
  return (
    <div className="FutureProjects">
      <h2>Future Projects</h2>
      <p>Host Website: Port Forwarding</p>
      <img
        src={require("../images/port_forward.png")}
        style={{ width: "900px" }}
        alt="port forwarding"
      />
      <a href="https://community.talktalk.co.uk/t5/Articles/Set-up-port-forwarding/ta-p/2205382">
        talktalk info
      </a>
    </div>
  );
}
