import React, { useState, useEffect } from "react";
import axios from "axios";
import cheerio from "cheerio";

// const url = "https://www.fieldglass.net/?next=%2Fworker_desktop.do%3Fcf%3D1";
const url = "https://gustavozapata.me/GZ1/university/gztoolbox/";
// const loginUrl = "https://www.fieldglass.net/login.do";
// const loginUrl = "https://gustavozapata.me/GZ1/university/gztoolbox/";
const loginUrl = "https://gustavozapata.me/GZ1/university/gztoolbox/index.php";
// const username = "gustavozapata";
const email = "test@test.com";
const password = "123";

export default function Test() {
  const [data, setData] = useState("");
  const [title, setTitle] = useState("");
  const [user, setUser] = useState("");

  useEffect(() => {
    axios.get(url).then(res => {
      setData(res.data);
      parseData(res.data);
    });
  }, []);

  const parseData = html => {
    const $ = cheerio.load(html);
    setTitle($(".form-signup h2").text());
    setUser($(".user-signed h2").text());
    // setUser($("$usernameId_new"));
  };

  const intentar = () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("login", "login");

    axios
      //   .post(loginUrl, { login: "Login", email: email, password: password })
      .post(loginUrl, formData)
      .then(
        response => {
          setData(response.data);
          console.log("data: ", response.data);
          console.log("status: ", response.status);
          console.log("statusText: ", response.statusText);
          console.log("headers: ", response.headers);
          console.log("config: ", response.config);
        },
        err => {
          console.log(err);
        }
      );
  };

  return (
    <div className="Test" style={{ textAlign: "left" }}>
      <h2>Test</h2>
      <h3>{title}</h3>
      <p>el input: {user}</p>
      <button onClick={intentar}>Submit</button>
      <p style={{ textDecoration: "none" }}>{data}</p>
    </div>
  );
}
