import React from "react";

export default function InFieldglass(props) {
  return (
    <div className="InFieldglass">
      <h3>Hello {props.user} from Fieldglass</h3>
      <div className="tables">
        <h4>Time in/Time out</h4>
        <table>
          <thead>
            <tr>
              <th>Day</th>
              <th>Mon</th>
              <th>Tue</th>
              <th>Wed</th>
              <th>Thru</th>
              <th>Fri</th>
              <th>Sat</th>
              <th>Sun</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Time in</th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
            </tr>
            <tr>
              <th>Time out</th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
            </tr>
            <tr>
              <th>total</th>
              <th>0</th>
              <th>0</th>
              <th>0</th>
              <th>0</th>
              <th>0</th>
              <th>0</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
        </table>

        <h4>Time Worked</h4>
        <table>
          <thead>
            <tr>
              <th>Day</th>
              <th>Mon</th>
              <th>Tue</th>
              <th>Wed</th>
              <th>Thru</th>
              <th>Fri</th>
              <th>Sat</th>
              <th>Sun</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="9">Billable</td>
            </tr>
            <tr>
              <td colSpan="9">CIIP</td>
            </tr>
            <tr>
              <th>ST/ hr</th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
              <th>
                <input />
              </th>
            </tr>
            <tr>
              <th>total</th>
              <th>0</th>
              <th>0</th>
              <th>0</th>
              <th>0</th>
              <th>0</th>
              <th>0</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
        </table>
      </div>

      <img
        src={require("../images/field.png")}
        alt="fieldglass"
        style={{ width: "950px" }}
      />
    </div>
  );
}
