import React from "react";

export default function PlayWeb() {
  return (
    <div className="PlayWeb">
      <h1>Playing around</h1>
      <div
        class="LI-profile-badge"
        data-version="v1"
        data-size="medium"
        data-locale="en_US"
        data-type="horizontal"
        data-theme="light"
        data-vanity="gustavo-zapata"
      >
        <a
          class="LI-simple-link"
          href="https://www.linkedin.com/in/gustavo-zapata?trk=profile-badge"
        >
          Gustavo Zapata
        </a>
      </div>
      <br />
      <a href="https://www.linkedin.com/badges/profile/create?vanityname=gustavo-zapata&preferredlocale=en_US&trk=public_profile-settings_badge">
        click here
      </a>{" "}
      to find out more
    </div>
  );
}
