import React, { useState } from "react";
import InFieldglass from "./InFieldglass";
import "../styles/Fieldglass.css";

export default function Fieldglass() {
  const [credentials, setCredentials] = useState({
    username: "",
    password: ""
  });
  const [logged, setLogged] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    if (credentials.username === "" && credentials.password === "") {
      setLogged(true);
    }
  };

  const onUserChange = e => {
    setCredentials({
      username: e.target.value
    });
  };

  const onPasswordChange = e => {
    setCredentials({
      username: credentials.username,
      password: e.target.value
    });
  };

  return (
    <div className="Fieldglass">
      {!logged ? (
        <React.Fragment>
          <h2>Login to Fieldglass</h2>
          <form onSubmit={onSubmit}>
            <input type="text" onChange={onUserChange} placeholder="username" />
            <br />
            <input
              type="password"
              onChange={onPasswordChange}
              placeholder="password"
            />
            <br />
            <button type="submit">Submit</button>
          </form>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <InFieldglass user={credentials.username} />
        </React.Fragment>
      )}
    </div>
  );
}
