import React, { useState } from "react";
import GitHubRepos from "./Projects/GitHubRepos";
import FutureProjects from "./Projects/FutureProjects";

export default function Projects() {
  const [currentPage, setCurrentPage] = useState("");

  const goTo = page => {
    setCurrentPage(page);
  };

  const renderPage = () => {
    switch (currentPage) {
      case "Github":
        return <GitHubRepos />;
      case "Future":
        return <FutureProjects />;
      default:
        return (
          <React.Fragment>
            <p onClick={() => goTo("Github")}>GitHub Repositories</p>
            <p onClick={() => goTo("Future")}>Future Projects</p>
          </React.Fragment>
        );
    }
  };
  return (
    <div className="Projects">
      <h2>Projects</h2>
      {renderPage()}
    </div>
  );
}
