import React, { useState } from "react";
import "./App.css";
import Header from "./components/Header";
import PlayWeb from "./pages/PlayWeb";
import WebScraping from "./pages/WebScraping";
import Projects from "./pages/Projects";

function App() {
  const [currentPage, setCurrentPage] = useState("Home");
  const [logged, setLogged] = useState(false);
  const [password, setPassword] = useState("");

  const onSubmit = e => {
    e.preventDefault();
    if (password === "cali2020") {
      setLogged(true);
    }
  };

  const onPasswordChange = e => {
    setPassword(e.target.value);
  };

  const goTo = page => {
    setCurrentPage(page);
  };
  const renderPage = () => {
    switch (currentPage) {
      case "PlayWeb":
        return <PlayWeb />;
      case "WebScraping":
        return <WebScraping />;
      case "Projects":
        return <Projects />;
      default:
        return (
          <React.Fragment>
            <p onClick={() => goTo("PlayWeb")}>
              <b>Play Web: </b>A site to play around with widgets, features,
              badges
            </p>
            <p onClick={() => goTo("WebScraping")}>
              <b>Web Scraping: </b>A site to test web scrapping (login, input
              this, input that, submit
            </p>
            <p onClick={() => goTo("Projects")}>
              <b>Projects: </b>A site with my GitHub projects and all the info
              in onenote (give permission to access this site with a code to
              people)
            </p>
          </React.Fragment>
        );
    }
  };
  return (
    <div className="App">
      <h1>Pro GZ</h1>
      {!logged ? (
        <React.Fragment>
          <form onSubmit={onSubmit}>
            <input type="password" onChange={onPasswordChange} />
            <br />
            <button type="submit">Submit</button>
          </form>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Header goTo={goTo} />
          {renderPage()}
        </React.Fragment>
      )}
    </div>
  );
}

export default App;
