import React, { useEffect, useState } from "react";
import "../styles/GitHubRepos.css";

export default function GitHubRepos() {
  const [repos, setRepos] = useState([]);

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    await fetch("https://api.github.com/users/gustavozapata/repos")
      .then(response => {
        return response.json();
      })
      .then(myJson => {
        setRepos(myJson);
      });
  };

  return (
    <div className="GitHubRepos">
      <h2>GitHub Repositories</h2>
      {repos.length > 0 &&
        repos.map((repo, index) => <p key={index}>{repo.name}</p>)}
      <h4>Total: {repos.length}</h4>
    </div>
  );
}
